import { useLocation } from 'react-router-dom'
import { useLayoutEffect } from 'react'


import styles from '../styles/Layout.module.scss'
import { Helmet } from 'react-helmet';

export default function Layout({ children }) {

    const location = useLocation();

    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <>
            <Helmet>
                <title>Qorby Checkout</title>
            </Helmet>
            <div className={styles.wrapper}>
                {children}
            </div>
        </>
    )
}