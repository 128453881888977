// TODO check quantities of line items

import { useEffect, useRef, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { useNavigate, Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import Modal from 'react-modal'

// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js'

import Cart from '../components/Cart'
import Breadcrumb from '../components/Breadcrumb'
import SubmitButton from '../components/forms/SubmitButton'
// import StripePayment from '../components/StripePayment'

import useCheckout from '../lib/checkoutProvider'
import useData from '../lib/dataProvider'

import styles from '../styles/Payment.module.scss'
import { MOLLIE_CREATE_PAYMENT, MOLLIE_LIST_PAYMENT_METHODS, STRIPE_CREATE_PAYMENT } from '../lib/graphql'
import TermsModal from '../components/TermsModal'
import PrivacyModal from '../components/PrivacyModal'


// const stripePromise = loadStripe(
//     process.env.REACT_APP_STRIPE_PUBLIC_KEY
// );

export default function Payment() {

    const [termsModalOpen, setTermsModalOpen] = useState(false)
    const toggleTermsModal = () => setTermsModalOpen(!termsModalOpen)
    const [privacyModalOpen, setPrivacyModalOpen] = useState(false)
    const togglePrivacyModal = () => setPrivacyModalOpen(!privacyModalOpen)

    useEffect(() => {
        const { pathname } = new URL(window.location)
        window._paq.push(['setCustomUrl', pathname]);
        window._paq.push(['setDocumentTitle', 'Payment']);
        window._paq.push(['trackPageView']);
    }, [])

    const { checkout } = useCheckout()
    const { countryOptions } = useData()
    const navigate = useNavigate()
    const [checkoutLoading, setCheckoutLoading] = useState(false)
    // const [stripeClientSecret, setStripeClientSecret] = useState(null)
    const [molliePaymentMethods, setMolliePaymentMethods] = useState(null)
    const [molliePaymentMethodSelected, setMolliePaymentMethodSelected] = useState(null)

    // const [checkoutPaymentStripeCreatePayment, { data: paymentData, loading: paymentLoading, error: paymentError }] = useMutation(STRIPE_CREATE_PAYMENT);
    const [checkoutPaymentMollieListPaymentMethods, { data: paymentMethodsData, loading: paymentMethodsLoading, error: paymentMethodsError }] = useMutation(MOLLIE_LIST_PAYMENT_METHODS);
    const [checkoutPaymentMolliePaymentCreate, { data: paymentCreateData, loading: paymentCreateLoading, error: paymentCreateError }] = useMutation(MOLLIE_CREATE_PAYMENT);

    useEffect(() => {
        if (checkout?.status !== 'OPEN' && checkout?.status !== 'PAYMENT_FAILED' && checkout?.status !== 'PAYMENT_PROCESSING') navigate(`/${checkout.uid}/complete`, { replace: true })
        if (!checkout.shippingAddress || !checkout.email) navigate(`/${checkout.uid}/shipping`, { replace: true })
    }, [checkout])

    useEffect(() => {
        // async function getStripeClientSecret() {
        //     const paymentIntent = await checkoutPaymentStripeCreatePayment({
        //         variables: {
        //             checkoutUid: checkout?.uid
        //         }
        //     })

        //     setStripeClientSecret(paymentIntent?.data?.checkoutPaymentStripeCreatePayment?.clientSecret)
        // }
        // getStripeClientSecret()
        async function getMolliePaymentMethods() {
            const molliePaymentMethods = await checkoutPaymentMollieListPaymentMethods({
                variables: {
                    checkoutUid: checkout?.uid,
                    applePay: window.ApplePaySession && window.ApplePaySession.canMakePayments() ? true : false
                }
            })
            setMolliePaymentMethods(molliePaymentMethods?.data?.checkoutPaymentMollieListPaymentMethods)
        }
        getMolliePaymentMethods()
    }, [])

    const createMolliePayment = async () => {
        await checkoutPaymentMolliePaymentCreate({
            variables: {
                checkoutUid: checkout?.uid,
                paymentMethod: molliePaymentMethodSelected
            }
        })
    }

    useEffect(() => {
        if (paymentCreateData && paymentCreateData?.checkoutPaymentMolliePaymentCreate?.redirectUrl) {
            window.location.href = paymentCreateData?.checkoutPaymentMolliePaymentCreate?.redirectUrl
        }
    }, [paymentCreateData])

    // const options = {
    //     clientSecret: stripeClientSecret,
    //     appearance: {
    //         labels: 'floating',
    //         variables: {
    //             fontSizeBase: '14px',
    //             colorPrimary: '#000000',
    //             colorText: '#000000',
    //             borderRadius: '5px',
    //         }
    //     }
    // }

    return (
        <div className="checkoutStepsWrapper">
            <div className="checkoutSteps">
                <Breadcrumb step={2} />
                <h2>Zusammenfassung</h2>
                <div className="spacer15"></div>
                <div className={styles.summaryItem}>
                    <h3>Kontaktdaten <span onClick={() => navigate(`/${checkout.uid}/shipping#contact`)}>Ändern</span></h3>
                    <p>E-Mail: {checkout?.email}</p>
                    {checkout?.phone && <p>Telefon: {checkout?.phone}</p>}
                    {checkout?.newsletter && <p>Newsletter abonnieren: Ja</p>}
                </div>
                <div className="spacer15"></div>
                <div className={styles.summaryItem}>
                    <h3>Lieferadresse <span onClick={() => navigate(`/${checkout.uid}/shipping#customerData`)}>Ändern</span></h3>
                    <p>{checkout?.shippingAddress?.company && `${checkout?.shippingAddress?.company}, `}{checkout?.shippingAddress?.firstName} {checkout?.shippingAddress?.lastName}, {checkout?.shippingAddress?.address1}{checkout?.shippingAddress?.address2 && ` (${checkout?.shippingAddress?.address2})`}, {checkout?.shippingAddress?.zip} {checkout?.shippingAddress?.city}, {countryOptions[checkout?.shippingAddress?.country?.code]}</p>
                </div>
                <div className="spacer15"></div>
                {checkout?.billingAddress && (
                    <>
                        <div className={styles.summaryItem}>
                            <h3>Rechnungsadresse <span onClick={() => navigate(`/${checkout.uid}/shipping#billingAddress`)}>Ändern</span></h3>
                            <p>{checkout?.billingAddress?.company && `${checkout?.billingAddress?.company}, `}{checkout?.billingAddress?.firstName} {checkout?.billingAddress?.lastName}, {checkout?.billingAddress?.address1}{checkout?.billingAddress?.address2 && ` (${checkout?.billingAddress?.address2})`}, {checkout?.billingAddress?.zip} {checkout?.billingAddress?.city}, {countryOptions[checkout?.billingAddress?.country?.code]}</p>
                        </div>
                        <div className="spacer15"></div>
                    </>
                )}

                <div className={styles.summaryItem}>
                    <h3>Versandart</h3>
                    {checkout?.carts?.map(cart => {
                        return (
                            <div className={styles.shippingRate} key={`cart-${cart?.id}`}>
                                <div className={styles.shippingRateTitle}>
                                    {cart?.merchant?.name} • {cart?.shippingRate?.title}
                                </div>
                                <div className={styles.shippingRateCost}>
                                    {cart?.shippingRate?.cost != 0 ? <CurrencyFormat value={cart?.shippingRate?.cost} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={' €'} decimalScale={2} fixedDecimalScale={true} /> : 'Kostenlos'}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="spacer15"></div>
                <h2>Zahlungsmittel</h2>
                <div className="spacer15"></div>
                {/* {stripeClientSecret && (
                    <Elements stripe={stripePromise} options={options}>
                        <StripePayment clientSecret={stripeClientSecret} checkoutUid={checkout?.uid} />
                    </Elements>
                )} */}
                {(molliePaymentMethods && !paymentMethodsLoading) ? (
                    <div className={styles.molliePaymentMethods}>
                        {molliePaymentMethods.map(method => (
                            <label key={`paymentmethod-${method.id}`} className={[styles.molliePaymentMethod, molliePaymentMethodSelected === method.id && styles.molliePaymentMethodActive].join(' ')}>
                                <div className={styles.molliePaymentMethodRadio}>
                                    <input type="radio" name="paymentMethod" value={method.id} onChange={() => setMolliePaymentMethodSelected(method.id)} />
                                </div>
                                <div className={styles.molliePaymentMethodIcon}>
                                    <img src={method.icon} alt={method.id} />
                                </div>
                                <div className={styles.molliePaymentMethodTitle}>
                                    {method.description}
                                </div>
                            </label>
                        ))}
                    </div>
                ) : (
                    <p>Lade Zahlungsmethoden…</p>
                )}
                <div className="spacer15"></div>
                <p className={styles.legal}>Mit deiner Bestellung erklärst du dich mit unseren <span className={styles.link} onClick={toggleTermsModal}>Nutzungsbedingungen</span> und <span className={styles.link} onClick={togglePrivacyModal}>Datenschutzbestimmungen</span> einverstanden.</p>
                <SubmitButton loading={paymentCreateLoading} disabled={!molliePaymentMethodSelected} value='Kostenpflichtig bestellen' onClickHandler={createMolliePayment} />
                <div className="spacer15"></div>
                <div className={styles.stepBack}><Link to={`/${checkout.uid}/shipping`}>Zurück zu Kontakt & Lieferung</Link></div>
                <div className="spacer15"></div>
            </div>
            <Cart />
            <Modal
                isOpen={termsModalOpen}
                onRequestClose={toggleTermsModal}
                className={styles.paymentModal}
            >
                <TermsModal toggleClose={toggleTermsModal} />
            </Modal>
            <Modal
                isOpen={privacyModalOpen}
                onRequestClose={togglePrivacyModal}
                className={styles.paymentModal}
            >
                <PrivacyModal toggleClose={togglePrivacyModal} />
            </Modal>
        </div >
    )
}