import { gql } from '@apollo/client'

export const GET_CHECKOUT = gql`
    query Checkout($checkoutUid: String!) {
        checkout(checkoutUid: $checkoutUid) {
            publisher {
                id
                profile {
                    handle
                    profileImageUrl
                }
            }
            uid
            reference
            email
            phone
            newsletter
            status
            paymentMethod
            customerCompletedAt
            updatedAt
            shippingAddress {
                id
                firstName
                lastName
                company
                address1
                address2
                zip
                city
                country {
                    code
                    name
                }
            }
            billingAddress {
                id
                firstName
                lastName
                company
                address1
                address2
                zip
                city
                country {
                    code
                    name
                }
            }
            estimatedCost {
				subtotalAmount {
					amount
					currency
				}
				totalTaxAmount {
					amount
					currency
				}
				totalAmount {
					amount
					currency
				}
                totalShippingAmount {
					amount
					currency
				}
			}
            carts {
                id
                status
                order {
                    externalOrderId
                }
                merchant {
                    name
                    profile {
                        logoUrl
                    }
                    availableShippingRates {
                        title
                        cost
                        type
                        country {
                            code
                            name
                        }
                    }
                }
                lineItems {
                    id
                    variantTitle
                    variantMediaUrl
                    variantSku
                    variantTotalAmount
                    variant { 
                        id
                        title
                        sku 
                        product {
                            title
                            brand {
                                name
                            }
                            handle
                            media {
                                mediaUrl
                            }
                            category {
                                name
                            }
                        }
                        prices {
                            amount
                            compareAmount
                            currency
                        }
                        media {
                            id
                            mediaUrl
                        }
                    }
                    quantity
                    estimatedCost {
                        amount
                        currency
                    }
                }
                shippingRate {
                    title
                    cost
                    type
                    country {
                        code
                        name
                    }
                }
                estimatedCost {
                    subtotalAmount {
                        amount
                        currency
                    }
                    totalTaxAmount {
                        amount
                        currency
                    }
                    totalAmount {
                        amount
                        currency
                    }
                }
                totalAmount
                totalTaxAmount
                shippingRateTitle
                shippingRateAmount
            }
        }
    }
`;

export const UPDATE_SHIPPING_ADDRESS = gql`
    mutation CheckoutShippingAddressUpdate($checkoutUid: String!, $shippingAddress: CheckoutShippingAddressInput!) {
        checkoutShippingAddressUpdate(checkoutUid: $checkoutUid, shippingAddress: $shippingAddress) {
            uid
            shippingAddress {
                id
                firstName
                lastName
                company
                address1
                address2
                zip
                city
                country {
                    code
                    name
                }
                phone
            }
            carts {
                id
                shippingRate {
                    title
                    cost
                    type
                    country {
                        code
                        name
                    }
                }
            }
            estimatedCost {
				subtotalAmount {
					amount
					currency
				}
				totalTaxAmount {
					amount
					currency
				}
				totalAmount {
					amount
					currency
				}
                totalShippingAmount {
					amount
					currency
				}
			}
        }
    }
`

export const UPDATE_BILLING_ADDRESS = gql`
    mutation CheckoutBillingAddressUpdate($checkoutUid: String!, $billingAddress: CheckoutBillingAddressInput, $remove: Boolean) {
        checkoutBillingAddressUpdate(checkoutUid: $checkoutUid, billingAddress: $billingAddress, remove: $remove) {
            uid
            billingAddress {
                id
                firstName
                lastName
                company
                address1
                address2
                zip
                city
                country {
                    code
                    name
                }
                phone
            }
        }
    }
`

export const UPDATE_CONTACT_DETAILS = gql`
    mutation CheckoutContactUpdate($checkoutUid: String!, $email: String!, $phone: String, $newsletter: Boolean) {
        checkoutContactUpdate(checkoutUid: $checkoutUid, email: $email, phone: $phone, newsletter: $newsletter) {
            uid
            email
            phone
            newsletter
        }
    }
`

export const STRIPE_CREATE_PAYMENT = gql`
    mutation CheckoutPaymentCreatePayment($checkoutUid: String!) {
        checkoutPaymentStripeCreatePayment(checkoutUid: $checkoutUid) {
            clientSecret
        }
    }
`

export const MOLLIE_LIST_PAYMENT_METHODS = gql`
    mutation CheckoutPaymentMollieListPaymentMethods($checkoutUid: String!, $applePay: Boolean) {
        checkoutPaymentMollieListPaymentMethods(checkoutUid: $checkoutUid, applePay: $applePay) {
            id
            description
            icon
        }
    }
`

export const MOLLIE_CREATE_PAYMENT = gql`
    mutation CheckoutPaymentMolliePaymentCreate($checkoutUid: String!, $paymentMethod: String!) {
        checkoutPaymentMolliePaymentCreate(checkoutUid: $checkoutUid, paymentMethod: $paymentMethod) {
            redirectUrl
        }
    }
`

export const NEWSLETTER_ACTIVATE = gql`
    mutation NewsletterActivate($activationHash: String!) {
        newsletterActivate(activationHash: $activationHash)
    }
`

export const RESET_PAYMENT = gql`
    mutation CheckoutPaymentReset($checkoutUid: String!) {
        checkoutPaymentReset(checkoutUid: $checkoutUid) {
            status
        }
    }
`