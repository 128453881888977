import React, { createContext, useState, useContext, useEffect } from 'react'
import { getLocalStorage, setLocalStorage } from '../lib/localStorage'
import countries from 'i18n-iso-countries'
import { useSearchParams } from 'react-router-dom'
import { faRefresh } from '@fortawesome/pro-regular-svg-icons';

const DataContext = createContext({});

export const DataProvider = ({ children }) => {

    const [search, setSearch] = useSearchParams()
    const [ref, setRef] = useState(() => search.get('ref'))

    const [customerData, setCustomerData] = useState(() => getLocalStorage('customerData'))

    countries.registerLocale(require("i18n-iso-countries/langs/de.json"));
    // const countryOptions = countries.getNames('de', { select: 'official' })
    const countryOptions = {
        AT: 'Österreich',
        DE: 'Deutschland'
    }

    useEffect(() => { setLocalStorage('customerData', customerData) }, [customerData])

    return (
        <DataContext.Provider value={{
            customerData,
            setCustomerData,
            countryOptions: countryOptions,
            ref
        }}>
            {children}
        </DataContext.Provider>
    )
};


export default function useData() {
    const context = useContext(DataContext)
    return context
};