import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/pro-regular-svg-icons'

import styles from '../styles/TermsModal.module.scss'

export default function TermsModal({ toggleClose }) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.close} onClick={toggleClose}>
                <FontAwesomeIcon icon={faX} size='xl' />
            </div>
            <h2>Nutzungsbedingungen</h2>
            <h3>1. Allgemeines</h3>
            <p>Die Qorby GmbH, Mariahilferstraße 24/7, 8020 Graz, Österreich (nachfolgend "Qorby" genannt), betreibt über die Plattform Qorby.com bzw. mehrere Social Media Commerce-Profile und externe Websites eine E-Commerce Marktplatz-Plattform. Über Qorby können sogn. Mikro-Marktplätze von sogn. “Publishern” erstellt und Produkte unterschiedlichster Art von gewerblichen Händlern an Käufer verkauft werden.</p>
            <p>Die nachfolgenden Nutzungsbedingungen bilden die Grundregeln für die Teilnahme aller Parteien (wie oben beschrieben) auf der Plattform.</p>
            <p>Durch die Nutzung der Mikro-Marktplätze und der Plattform erklären Sie sich mit diesen Nutzungsbedingungen einverstanden.</p>
            <h3>2. Beteiligung an der Plattform</h3>
            <p>Qorby ist niemals Vertragspartner eines Kaufvertrages. Der Kaufvertrag kommt nur direkt zwischen den beteiligten Käufern und Händlern zustande. Desweiteren werden sowohl Produkte, als auch andere Inhalte und Daten von Käufern und Händlern seitens Qorby nicht geprüft und keine Haftung für diese übernommen.</p>
            <h3>3. Kaufvertragsschluss</h3>
            <p>3.1. Die auf der Plattform bzw. in den Mikro-Marktplätzen befindlichen Angebote stellen keine verbindlichen Kaufangebote dar. Zu einem Vertragsschluss zwischen Käufer und Händler kommt es erst, wenn die Bestellung des Käufers durch Händler verbindlich angenommen wird. Mit dem Absenden der Bestellung bzw. der Bezahlung akzeptiert der Käufer automatisch die Allgemeinen Geschäftsbedingungen, Widerrufsbedingungen und die Datenschutzerklärung des jeweiligen Händlers (diese Inhalte können bei jedem Angebot eingesehen werden). Nach erfolgter Bestellung und Bezahlung wird von Qorby eine Bezahlbestätigung und je Händler eine Bestellbestätigung vom Händler per E-Mail an den Käufer übermittelt. Die Bezahlbestätigung von Qorby gilt nicht als Annahme der Bestellung durch die Händler. Nach Eingang der Zahlung wird die Bestellung an den jeweiligen Händler übermittelt und dort eine neue Bestellung angelegt.</p>
            <p>3.2. Die Bezahlung der Bestellung wird über Qorby abgewickelt. Näheres dazu siehe 4. Bezahlung.</p>
            <p>3.3. Für die Bestellung bzw. den Versand der Produkte ist ausschließlich der jeweilige Händler zuständig.</p>
            <h3>4. Bezahlung</h3>
            <p>4.1. Die Bezahlung von Warenkäufen erfolg ausschließlich über Qorby. Hierfür stellt Qorby ein Payment-Service zur Verfügung, bei dem mit unterschiedlichen Bezahlmethoden bezahlt werden kann. Eine Anpassung des Payment-Service (z.B. des Payment-Providers und der angebotenen Bezahlmethoden) kann von Qorby jederzeit vorgenommen werden. Aktuell werden Zahlungen über den Payment Provider Mollie B.V. abgewickelt.</p>
            <p>4.2. Die Rechnungslegung der einzelnen Bestellungen erfolgt direkt vom jeweiligen Händler.</p>
            <h3>5. Gewährleistung und Haftung</h3>
            <p>Qorby ist niemals Vertragspartner eines Kaufgeschäfts zwischen Käufer und Händler und übernimmt keine Gewährleistung und Haftung für den Kaufgegenstand (sowohl für das Produkt, als auch z.B. den Versand). Qorby ist rein für die Bereitstellung der Plattform, über die der Kauf erfolgt, verantwortlich.</p>
            <p><i>Graz, 25.07.2022</i></p>
        </div>
    )
}