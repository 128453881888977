import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird, faCheck, faTruck, faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import CurrencyFormat from 'react-currency-format'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { de } from 'date-fns/locale'
import { useMutation } from '@apollo/client'

import useCheckout from '../lib/checkoutProvider'

import styles from '../styles/Complete.module.scss'
import { RESET_PAYMENT } from '../lib/graphql'


export default function Complete() {

    useEffect(() => {
        const { pathname } = new URL(window.location)
        window._paq.push(['setCustomUrl', pathname]);
        window._paq.push(['setDocumentTitle', 'Complete / Order-Details']);
        window._paq.push(['trackPageView']);
    }, [])

    const { checkout, getCheckout } = useCheckout()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [orderTracked, setOrderTracked] = useState(false)

    const [checkoutPaymentReset, { data: paymentResetData, loading: paymentResetLoading, error: paymentResetError }] = useMutation(RESET_PAYMENT);

    const resetPayment = async () => {
        await checkoutPaymentReset({
            variables: {
                checkoutUid: checkout?.uid
            }
        })
    }

    useEffect(() => {
        if (paymentResetData && checkout?.status === 'OPEN') {
            navigate(`/${checkout.uid}/payment`, { replace: true })
        }
    }, [paymentResetData, checkout])

    useEffect(() => {
        // TODO Pass payment failed error
        if (!checkout?.shippingAddress || !checkout?.email) navigate(`/${checkout.uid}/shipping`, { replace: true })
        if (checkout?.status === 'OPEN' || checkout?.status === 'PAYMENT_FAILED') navigate(`/${checkout.uid}/payment`, { replace: true })

        if (checkout && !orderTracked) {
            checkout?.carts.map(cart => {
                cart?.lineItems.map(line => {
                    window._paq.push(['addEcommerceItem',
                        line?.variant?.id,
                        `${line?.variant?.product?.title}${line?.variant?.title ? `- ${line?.variant?.title}` : null}`,
                        line?.variant?.product?.category?.name,
                        parseFloat(line?.estimatedCost?.amount),
                        line?.quantity
                    ])
                })
            })
            window._paq.push(['trackEcommerceOrder',
                checkout?.uid,
                parseFloat(checkout?.estimatedCost?.totalAmount?.amount),
                parseFloat(checkout?.estimatedCost?.subtotalAmount?.amount),
                parseFloat(checkout?.estimatedCost?.totalTaxAmount?.amount),
                parseFloat(checkout?.estimatedCost?.totalShippingAmount?.amount),
                false
            ])
            setOrderTracked(true)
        }
    }, [checkout])

    useEffect(() => {
        const getCheckoutInterval = setInterval(() => {
            getCheckout()
        }, 5000);
        return () => clearInterval(getCheckoutInterval);
    }, [])

    return (
        <>
            {(paymentResetData || paymentResetLoading) && (
                <div className={styles.loadingWrapper}>
                    <p>Einen Moment bitte…</p>
                </div>
            )}
            <div className={styles.completeWrapper}>
                <div className={styles.orderHeader}>
                    <h1>Deine Bestellung {checkout?.reference}</h1>
                    <div className={styles.customerCreatedAt}><span>{format(new Date(parseInt(checkout?.customerCompletedAt || checkout?.updatedAt)), 'dd.MM.yyyy HH:mm', { addSuffix: true, locale: de })}</span></div>
                </div>
                <p>Vielen Dank für deinen Einkauf. Sobald deine Zahlung bestätigt wurde, wird deine Bestellung an die jeweiligen Händler weitergeleitet.</p>
                <div className="spacer15"></div>
                {(checkout?.status === 'PAYMENT_PROCESSING' || checkout?.status === 'OPEN') &&
                    <>
                        <div className={styles.noticeWaiting}>
                            <FontAwesomeIcon icon={faSpinnerThird} spin className={styles.spinner} size="xl" />
                            <p>Wir warten auf die <b>Bestätigung deiner Zahlung</b>. Je nach Bezahlmethode kann dies etwas dauern.</p>
                        </div>
                        <div className={styles.noticeReset}>
                            <FontAwesomeIcon icon={faCircleExclamation} className={styles.alertIcon} size="xl" />
                            <p>Du hast ein <b>Problem</b> mit deiner Bezahlmethode? <a onClick={resetPayment}>Klicke hier um deine Bezahlmethode zu ändern.</a></p>
                        </div>
                    </>
                }
                {(checkout?.status === 'PAYMENT_SUCCESSFUL' || checkout?.status === 'PROCESSING' || checkout?.status === 'COMPLETE') &&
                    <>
                        <div className={styles.noticeSuccess}>
                            <FontAwesomeIcon icon={faCheck} size="xl" color="#064420" />
                            <p>Bestellung wurde mit <b>{t(checkout?.paymentMethod)}</b> bezahlt.</p>
                        </div>
                    </>
                }
                {(checkout?.status === 'PROCESSING' || checkout?.status === 'PAYMENT_SUCCESSFUL') &&
                    <>
                        <div className={styles.noticeWaiting}>
                            <FontAwesomeIcon icon={faSpinnerThird} spin className={styles.spinner} size="xl" />
                            <p>Deine Bestellung wird nun an die zuständigen Händler übertragen. Dies kann einen Moment dauern.</p>
                        </div>
                    </>
                }
                {checkout?.status === 'COMPLETE' &&
                    <>
                        <div className={styles.noticeSuccess}>
                            <FontAwesomeIcon icon={faCheck} size="xl" color="#064420" />
                            <p>Bestellung wurde an alle Händler übertragen. Bei Fragen zu deiner Bestellung, wende dich bitte, unter Angabe der Händler-Bestellnummer (ersichtlich beim jeweiligen Warenkorb weiter unten), direkt an den Händler.</p>
                        </div>
                    </>
                }
                <hr />
                <div className={styles.addresses}>
                    <div id="contactDetails">
                        <h2>Kontaktdaten</h2>
                        <p>
                            <b>E-Mail:</b> {checkout?.email}
                            {checkout?.phone && <><br /><b>Telefon:</b> {checkout?.phone}</>}
                        </p>
                    </div>
                    <div id="shippingAddress">
                        <h2>Versandadresse</h2>
                        <p className={styles.address}>
                            {checkout?.shippingAddress?.company && <span>{checkout?.shippingAddress?.company}</span>}
                            <span>{checkout?.shippingAddress?.firstName} {checkout?.shippingAddress?.lastName}</span>
                            <span>{checkout?.shippingAddress?.address1}{checkout?.shippingAddress?.address2 && ', ' + checkout?.shippingAddress?.address2}</span>
                            <span>{checkout?.shippingAddress?.zip} {checkout?.shippingAddress?.city}</span>
                            <span>{checkout?.shippingAddress?.country.name}</span>
                        </p>

                    </div>
                </div>
                <div className={styles.addresses}>
                    {checkout?.billingAddress &&
                        <div id="billingAddress">
                            <h2>Rechnungsadresse</h2>
                            <p className={styles.address}>
                                {checkout?.billingAddress?.company && <span>{checkout?.billingAddress?.company}</span>}
                                <span>{checkout?.billingAddress?.firstName} {checkout?.billingAddress?.lastName}</span>
                                <span>{checkout?.billingAddress?.address1}{checkout?.billingAddress?.address2 && ', ' + checkout?.billingAddress?.address2}</span>
                                <span>{checkout?.billingAddress?.zip} {checkout?.billingAddress?.city}</span>
                                <span>{checkout?.billingAddress?.country.name}</span>
                            </p>
                        </div>
                    }
                </div>
                <hr />
                <div className={styles.carts}>
                    {checkout?.carts.map(cart => (
                        <div className={styles.cart} key={`cart-${cart.id}`}>
                            <div className={styles.cartHeader}>
                                <div className={styles.cartLogo}>
                                    <figure>
                                        <img src={`${process.env.REACT_APP_MEDIA_URL}${cart?.merchant?.profile?.logoUrl}`} alt="logo" />
                                    </figure>
                                </div>
                                <div className={styles.cartTitle}>Bestellung bei <b>{cart?.merchant?.name}</b> </div>
                                <div className={styles.cartStatus}>
                                    <span>
                                        {(cart?.status == 'OPEN' || cart?.status == 'PROCESSING') && 'In Bearbeitung'}
                                        {cart?.status == 'COMPLETE' && 'Fertiggestellt'}
                                    </span>
                                </div>
                            </div>
                            {cart?.order?.externalOrderId && (
                                <div className={styles.externalOrderId}>
                                    <b>Bestellnummer (Händler):</b> {cart?.order?.externalOrderId}
                                </div>
                            )}
                            <div className={styles.cartLines}>
                                {cart?.lineItems.map(line => (
                                    <div className={styles.cartLine} key={line?.id}>
                                        <div className={styles.cartLineImage}>
                                            <figure>
                                                <img src={line?.variantMediaUrl} loading="lazy" alt={line?.variantTitle} />
                                            </figure>
                                        </div>
                                        <div className={styles.cartLineTitle}>
                                            {line?.quantity}x {line?.variantTitle}
                                            {line?.variantSku && <span className={styles.cartLineSku}>Art.-Nr.: {line?.variantSku}</span>}
                                        </div>
                                        <div className={styles.cartLineCost}>
                                            <CurrencyFormat value={line?.variantTotalAmount || 0.0} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={' €'} decimalScale={2} fixedDecimalScale={true} />
                                        </div>
                                    </div>
                                ))}
                                <div className={styles.cartLine}>
                                    <div className={styles.cartLineImage}>
                                        <FontAwesomeIcon icon={faTruck} size="xl" />
                                    </div>
                                    <div className={styles.cartLineTitle}>
                                        Versand ({cart?.shippingRateTitle})
                                    </div>
                                    <div className={styles.cartLineCost}>
                                        {cart?.shippingRateAmount == 0.0 ? 'Kostenlos' : <CurrencyFormat value={cart?.shippingRateAmount || 0.0} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={' €'} decimalScale={2} fixedDecimalScale={true} />}
                                    </div>
                                </div>
                                <hr />
                                <div className={styles.cartTotals}>
                                    <div className={styles.cartTotalsTitle}>
                                        <b>Gesamt</b>
                                        <span className={styles.cartTotalsTax}>inkl. <CurrencyFormat value={cart?.totalTaxAmount || 0.0} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={' €'} decimalScale={2} fixedDecimalScale={true} /> MwSt.</span>
                                    </div>
                                    <div className={styles.cartTotalsCost}>
                                        <CurrencyFormat value={cart?.totalAmount || 0.0} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={' €'} decimalScale={2} fixedDecimalScale={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}