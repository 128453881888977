import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/pro-regular-svg-icons'

import styles from '../styles/TermsModal.module.scss'

export default function PrivacyModal({ toggleClose }) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.close} onClick={toggleClose}>
                <FontAwesomeIcon icon={faX} size='xl' />
            </div>
            <h2>Datenschutzerklärung</h2>
            <p>Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Plattform.</p>
            <h3>Kontakt mit uns</h3>
            <p>Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit uns aufnehmen, werden Ihre angegebenen Daten (Name, E-Mail) zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Wenn Ihre Anfrage für uns nicht von Interesse ist, erfolgt eine umgehende Löschung dieser Anfrage mit allen personenbezogenen Daten. In allen anderen Fällen halten wir Ihre Daten solange in Evidenz bis Sie Ihren Widerruf an <a href="mailto:support@qorby.com">support@qorby.com</a> übermitteln. Die Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>
            <h3>Plattform (Datenspeicherung)</h3>
            <p>Zum Zweck der Vertragsabwicklung zwischen Ihnen und teilnehmenden Händlern werden folgende Daten bei uns gespeichert: Vorname, Nachname, Firmenname, Land, Straße, Ort/Stadt,  Postleitzahl, Land, Telefonnummer sowie E-Mail-Adresse des Käufers. Die von Ihnen bereitgestellten Daten sind zur Vertragserfüllung zwischen Ihnen und teilnehmenden Händlern bzw. zur Durchführung vorvertraglicher Maßnahmen erforderlich. Ohne diese Daten können wir Ihre Bestellung nicht abschließen. Eine Datenübermittlung nur Händler, bei denen Sie Produkte bestellen. Ein SSL-Zertifikat wird eingesetzt, um Ihre Website-Daten beim Aufruf durch den Browser sicher zu übertragen. Nach Abbruch des Einkaufsvorganges werden die bei uns gespeicherten Daten nach einer Frist von 30 Tagen gelöscht. Im Falle eines Vertragsabschlusses werden sämtliche Daten aus der Bestellung bis zum Ablauf der steuerrechtlichen Aufbewahrungsfrist (7 Jahre) gespeichert. Die für Sie möglichen Bezahlvorgänge erfordern zusätzliche Daten, die zur Zahlungsabwicklung beim Diensteanbieter benötigt werden, aber nicht bei uns gespeichert werden. Die Datenverarbeitung erfolgt auf Basis der gesetzlichen Bestimmungen des § 96 Abs. 3 TKG sowie des Art 6 Abs. 1 lit a (Einwilligung) und/oder lit b (notwendig zur Vertragserfüllung) der DSGVO. Es gelten zudem die Datenschutzbestimmungen des jeweiligen Händlern, mit dem Sie einen Kaufvertrag eingehen.</p>
            <h3>Cookies</h3>
            <p>Unsere Website verwendet sogenannte Cookies. Dabei handelt es sich um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an. Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben. Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein und sich nachteilig auf das Erscheinungsbild und Ihre Nutzererfahrung auswirken.</p>
            <h3>Web-Analyse mit Matomo</h3>
            <p>Wir nutzen auf unserer Webseite das Open-Source-Software-Tool Matomo, die auf einem virtuellen Server des Rechenzentrums Hetzner Online GmbH, Sigmundstraße 135, 90431 Nürnberg, Deutschland, aufgesetzt wurde. Damit ist gewährleistet, dass keine Daten an Dritte übermittelt werden, und die Daten unter unserer Kontrolle bleiben. Die an Matomo übermittelten Daten beinhalten nur Ihren Warenkorb und keine persönlichen Daten.</p>
            <h3>SSL-Verschlüsselung</h3>
            <p>Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel von Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und/oder an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</p>
            <h3>Ihre Rechte</h3>
            <p>Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. In Österreich ist dies die Datenschutzbehörde.</p>
            <p>Sie erreichen uns unter folgenden Kontaktdaten:<br />
                Qorby GmbH<br />
                Mariahilferstraße 24/7<br />
                8020 Graz<br />
                T. +43 660 3215305<br />
                E. <a href="mailto:support@qorby.com">support@qorby.com</a>
            </p>
        </div>
    )
}