import { faFaceSmileHearts } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from '../styles/Home.module.scss'

export default function Home({ }) {
    return (
        <div className={styles.wrapper}>
            <FontAwesomeIcon icon={faFaceSmileHearts} size={'2xl'} />
        </div>
    )
}