import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLifeRing, faShieldCheck } from '@fortawesome/pro-regular-svg-icons'
import Modal from 'react-modal'
import { useState } from 'react'

import useCheckout from '../lib/checkoutProvider'
import useData from '../lib/dataProvider'

import styles from '../styles/Header.module.scss'
import HelpModal from './HelpModal'


export default function Header() {

    const { checkout } = useCheckout()
    const { ref } = useData()
    const [supportModalOpen, setSupportModalOpen] = useState(false)

    const toggleSupportModal = () => setSupportModalOpen(!supportModalOpen)

    return (
        <>
            <div className={styles.header}>
                <div className={styles.profile}>
                    {ref ? (
                        <a href={ref}>
                            <figure className={styles.profileImage}>
                                <img src={`${process.env.REACT_APP_MEDIA_URL}${checkout?.publisher?.profile?.profileImageUrl}`} alt="Publisher Icon" />
                            </figure>
                        </a>
                    ) : (
                        <figure className={styles.profileImage}>
                            <img src={`${process.env.REACT_APP_MEDIA_URL}${checkout?.publisher?.profile?.profileImageUrl}`} alt="Publisher Icon" />
                        </figure>
                    )}
                    <div className={styles.profileHandle}>
                        Sicherer Checkout <FontAwesomeIcon icon={faShieldCheck} />
                    </div>
                </div>
                <div className={styles.help} onClick={toggleSupportModal}>
                    <FontAwesomeIcon icon={faLifeRing} /> Hilfe benötigt?
                </div>
            </div>
            <Modal
                isOpen={supportModalOpen}
                onRequestClose={toggleSupportModal}
                className={styles.helpModal}
                ariaHideApp={false}
            >
                <HelpModal toggleClose={toggleSupportModal} />
            </Modal>
        </>
    )
}