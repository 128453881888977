import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  de: {
    translation: {
      'card': 'Kreditkarte',
      'creditcard': 'Kreditkarte',
      'sofort': 'SOFORT Überweisung',
      'eps': 'EPS',
      'giropay': 'giropay',
      'paypal': 'PayPal',
    }
  },
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'de',
    interpolation: {
      escapeValue: false
    }
  })

export default i18n