import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import useData from '../lib/dataProvider'

export default function Redirect() {

    const { checkoutUid } = useParams()
    const { ref } = useData()
    const navigate = useNavigate()

    useEffect(() => {
        navigate({ pathname: `/${checkoutUid}/shipping`, search: ref && `?ref=${encodeURIComponent(ref)}` }, { replace: true })
    }, [])

    return null
}