import styles from '../styles/LoadingCheckout.module.scss'

export default function LoadingCheckout() {

    return (
        <div className={styles.loading}>
            {/* <ShoppingCart size={50} /> */}
            <p>Warenkorb wird geladen…</p>
        </div>
    )
}