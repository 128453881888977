import CurrencyFormat from 'react-currency-format'
import classnames from 'classnames'
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons'

import styles from '../styles/Cart.module.scss'

import useCheckout from '../lib/checkoutProvider'
import { Fragment, useState } from 'react'

export default function Cart({ }) {

    const { checkout } = useCheckout()
    const [openMobilebasket, setOpenMobilebasket] = useState(false)

    const handleOpenMobilebasket = () => {
        if (openMobilebasket) setOpenMobilebasket(false)
        else setOpenMobilebasket(true)
    }

    return (
        <div className={styles.cartWrapper}>
            <div className={styles.mobileNav} onClick={handleOpenMobilebasket}>
                <div className={styles.cartHeader}>
                    <span>Warenkorb anzeigen</span> {!openMobilebasket ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}
                </div>
                <div>
                    <CurrencyFormat value={checkout?.estimatedCost?.totalAmount?.amount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={' €'} decimalScale={2} fixedDecimalScale={true} />
                </div>
            </div>
            <div className={classnames(styles.cart, openMobilebasket ? styles.opened : null)}>
                <table>
                    <tbody>
                        {checkout?.carts?.map(cart => {
                            return (
                                <Fragment key={`cart-merchant-${cart?.id}`}>
                                    <tr className={styles.merchant}><td colSpan="2">Verkauf & Versand durch <b>{cart?.merchant?.name}</b>:</td></tr>
                                    {cart?.lineItems?.map(line => {
                                        return (
                                            <tr className={styles.lines} key={`cartLine-${line?.variant?.id}`}>
                                                <td>{line?.variant?.product?.title}{line?.variant?.title && ` - ${line?.variant?.title}`}<br /><small>Anzahl: {line?.quantity}</small></td>
                                                <td className={styles.lineSum}>
                                                    <CurrencyFormat value={line?.estimatedCost?.amount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={' €'} decimalScale={2} fixedDecimalScale={true} />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </Fragment>
                            )
                        })}
                        <tr>
                            <td colSpan="3"><hr /></td>
                        </tr>
                        <tr>
                            <td style={{ width: '75%' }}>Zwischensumme</td>
                            <td style={{ width: '25%' }} className={styles.lineSum}>
                                <b>
                                    <CurrencyFormat value={checkout?.estimatedCost?.subtotalAmount?.amount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={' €'} decimalScale={2} fixedDecimalScale={true} />
                                </b>
                            </td>
                        </tr>
                        <tr>
                            <td>Versandkosten<br /><small>{checkout?.carts?.length} Händler</small></td>
                            <td className={styles.lineSum}>
                                {
                                    checkout?.estimatedCost?.totalShippingAmount ? (
                                        <b>
                                            {checkout?.estimatedCost?.totalShippingAmount?.amount !== 0.0 ? (
                                                <CurrencyFormat value={checkout?.estimatedCost?.totalShippingAmount?.amount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={' €'} decimalScale={2} fixedDecimalScale={true} />
                                            )
                                                : (
                                                    'Kostenlos'
                                                )
                                            }
                                        </b>
                                    )
                                        : (
                                            'Berechnung im nächsten Schritt'
                                        )
                                }

                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3"><hr /></td>
                        </tr>
                        <tr className={styles.totals}>
                            <td className={styles.totalsTitle}>Gesamt<br /><small>inkl. <CurrencyFormat value={checkout?.estimatedCost?.totalTaxAmount?.amount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={' €'} decimalScale={2} fixedDecimalScale={true} /> MwSt.</small></td>
                            <td className={styles.totalsSum}>
                                <CurrencyFormat value={checkout?.estimatedCost?.totalAmount?.amount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={' €'} decimalScale={2} fixedDecimalScale={true} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}