import classnames from 'classnames'
import styles from '../../styles/SelectField.module.scss'

export default function SelectField({ label, name, value, error, required, register, options }) {
    return (
        <div className={styles.fieldWrapper}>
            <label htmlFor={name} className={styles.title}>{label}</label>
            <select name={name} defaultValue={value} className={classnames(styles.field, error ? styles.errorBorder : null)} {...register(name, { required: required ?? false })}>
                {Object.entries(options).map(([key, value]) => <option key={`select-${key}-${value}`} value={key}>{value}</option>)}
            </select>
            {error && <span className={styles.error}>Bitte fülle dieses Feld aus!</span>}
        </div >
    )
}