import { ApolloClient, HttpLink, InMemoryCache, defaultDataIdFromObject } from '@apollo/client'

export const client = new ApolloClient({
    link: new HttpLink({
        uri: process.env.REACT_APP_API_URL,
    }),
    cache: new InMemoryCache({
        dataIdFromObject(responseObject) {
            switch (responseObject.__typename) {
                case 'Checkout': return `Checkout:${responseObject.uid}`;
                default: return defaultDataIdFromObject(responseObject);
            }
        }
    }),
});
