import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/pro-regular-svg-icons'

import styles from '../styles/HelpModal.module.scss'

export default function HelpModal({ toggleClose }) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.close} onClick={toggleClose}>
                <FontAwesomeIcon icon={faX} size='xl' />
            </div>
            <h2>Benötigst du Hilfe?</h2>
            <p>Wir stehen dir beim Checkout gerne zur Seite. Kontaktiere uns einfach über die folgenden Optionen:</p>
            <ul>
                <li><b>E-Mail:</b> <a href="mailto:support@qorby.com">support@qorby.com</a></li>
                <li><b>Telefon:</b> <a href="tel:+43 660 3215305">+43 660 3215305</a></li>
            </ul>
        </div>
    )
}