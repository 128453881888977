import classnames from 'classnames'
import styles from '../../styles/InputField.module.scss'

export default function InputField({ label, name, value, setValue, error, required, register, type, validate, placeholder }) {
    return (
        <div className={styles.fieldWrapper}>
            <label htmlFor={name} className={styles.title}>{label}</label>
            <input defaultValue={value} type={type} name={name} placeholder={placeholder} className={classnames(styles.field, error ? styles.errorBorder : null)}  {...register(name, { required: required ?? false, validate: validate })} />
            {error && <span className={styles.error}>Bitte fülle dieses Feld aus!</span>}
        </div>
    )
}