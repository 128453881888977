import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'

import styles from '../styles/Breadcrumb.module.scss'

export default function Breadcrumb({ step }) {

    return (
        <div className={styles.breadcrumb}>
            <ul>
                <li className={step == 1 ? styles.active : null}>Kontakt & Lieferung</li>
                <li><FontAwesomeIcon icon={faChevronRight} /></li>
                <li className={step == 2 ? styles.active : null}>Zusammenfassung & Bezahlung</li>
            </ul>
        </div>
    )
}