import React, { createContext, useState, useContext, useEffect } from 'react'
import { useQuery, useLazyQuery } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'

import LoadingCheckout from '../components/LoadingCheckout'

import { GET_CHECKOUT } from './graphql'


const CheckoutContext = createContext({});

export const CheckoutProvider = ({ children }) => {

    const [checkout, setCheckout] = useState(null)
    const { checkoutUid } = useParams()

    const [getCheckout, {
        called: checkoutCalled,
        loading: checkoutLoading,
        data: checkoutData
    }] = useLazyQuery(GET_CHECKOUT, {
        variables: { checkoutUid: checkoutUid },
        onCompleted: data => {
            setCheckout(data.checkout)
        },
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        if (checkoutUid) {
            getCheckout()
        }
    }, [checkoutUid]);

    return (
        checkout
            ?
            <CheckoutContext.Provider value={{
                checkout: checkout,
                getCheckout: getCheckout
            }}>
                {children}
            </CheckoutContext.Provider>
            :
            <LoadingCheckout />
    )
};


export default function useCheckout() {
    const context = useContext(CheckoutContext)
    return context
};