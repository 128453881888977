import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { ApolloProvider } from '@apollo/client'
import { client } from './lib/apolloClient'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { CheckoutProvider } from './lib/checkoutProvider'
import { DataProvider } from './lib/dataProvider'

import './styles/globals.scss'
import Layout from './layouts/Layout'

import Home from './routes/Home'
import Shipping from './routes/Shipping'
import Header from './components/Header'
import Payment from './routes/Payment'
import Redirect from './routes/Redirect'
import Complete from './routes/Complete'
import NewsletterActivation from './routes/NewsletterActivation'

import './i18n'


function App() {

  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: "https://42a906183348482da11d180e46d0e951@exc.trnc.wtf/14"
    })
  }

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout><Home /></Layout>} />
          <Route path="newsletter/activate/:activationHash" element={<Layout><NewsletterActivation /></Layout>} />
          <Route path=":checkoutUid" element={<DataProvider><Layout><Header /><Redirect /></Layout></DataProvider>} />
          <Route path=":checkoutUid/shipping" element={<CheckoutProvider><DataProvider><Layout><Header /><Shipping /></Layout></DataProvider></CheckoutProvider>} />
          <Route path=":checkoutUid/payment" element={<CheckoutProvider><DataProvider><Layout><Header /><Payment /></Layout></DataProvider></CheckoutProvider>} />
          <Route path=":checkoutUid/complete" element={<CheckoutProvider><Layout><Header /><Complete /></Layout></CheckoutProvider>} />
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
