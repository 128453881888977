import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import countries from 'i18n-iso-countries'

import Cart from '../components/Cart'
import InputField from '../components/forms/InputField'
import CheckboxField from '../components/forms/CheckboxField'
import SubmitButton from '../components/forms/SubmitButton'
import SelectField from '../components/forms/SelectField'
import Breadcrumb from '../components/Breadcrumb'

import useData from '../lib/dataProvider'
import useCheckout from '../lib/checkoutProvider'

import { UPDATE_SHIPPING_ADDRESS, UPDATE_BILLING_ADDRESS, UPDATE_CONTACT_DETAILS } from '../lib/graphql'

import styles from '../styles/Shipping.module.scss'

export default function Shipping() {

    const navigate = useNavigate()
    const { checkout } = useCheckout()

    useEffect(() => {
        const { pathname } = new URL(window.location)
        window._paq.push(['setCustomUrl', pathname]);
        window._paq.push(['setDocumentTitle', 'Shipping']);
        window._paq.push(['trackPageView']);
    }, [])

    useEffect(() => {
        if (checkout?.status !== 'OPEN') navigate(`/${checkout.uid}/complete`, { replace: true })
    }, [checkout])

    const [checkoutShippingAddressUpdate, { data, loading, error }] = useMutation(UPDATE_SHIPPING_ADDRESS);
    const [checkoutBillingAddressUpdate, { data: billingData, loading: billingLoading, error: billingError }] = useMutation(UPDATE_BILLING_ADDRESS);
    const [checkoutContactUpdate, { data: contactData, loading: contactLoading, error: contactError }] = useMutation(UPDATE_CONTACT_DETAILS);

    const { customerData, setCustomerData, countryOptions } = useData()
    const { register, handleSubmit, watch, formState: { errors } } = useForm()
    const watchBillingAddress = watch('billingAddress', checkout?.billingAddress)

    const isValidEmail = email =>
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email
        )

    const isValidPhone = phone => /^(\+[1-9]{1}[0-9]{3,14})$/.test(phone)

    const handleEmailValidation = email => {
        const isValid = isValidEmail(email)

        // const validityChanged =
        //     (errors.email && isValid) || (!errors.email && !isValid)
        // if (validityChanged) {
        //     console.log("Fire tracker with", isValid ? "Valid" : "Invalid")
        // }

        return isValid
    }

    const handlePhoneValidation = phone => {
        if (phone === '') return

        const isValid = isValidPhone(phone)
        return isValid
    }

    const onSubmit = async data => {
        const email = data.email
        const phone = data.phone
        const newsletter = data.newsletter

        checkoutContactUpdate({
            variables: {
                checkoutUid: checkout.uid,
                email: email,
                phone: phone,
                newsletter: !!newsletter
            }
        })

        const shippingAddress = {
            firstName: data.firstName,
            lastName: data.lastName,
            company: data.company,
            address1: data.address1,
            address2: data.address2,
            zip: data.zip,
            city: data.city,
            country: data.country
        }

        checkoutShippingAddressUpdate({
            variables: {
                checkoutUid: checkout.uid,
                shippingAddress: shippingAddress
            }
        })

        if (data.billingAddress === 'true') {
            const billingAddress = {
                firstName: data.billingFirstName,
                lastName: data.billingLastName,
                company: data.billingCompany,
                address1: data.billingAddress1,
                address2: data.billingAddress2,
                zip: data.billingZip,
                city: data.billingCity,
                country: data.billingCountry
            }

            checkoutBillingAddressUpdate({
                variables: {
                    checkoutUid: checkout.uid,
                    billingAddress: billingAddress
                }
            })
        }

        if (data.billingAddress !== 'true' && checkout?.billingAddress) {
            checkoutBillingAddressUpdate({
                variables: {
                    checkoutUid: checkout.uid,
                    remove: true
                }
            })
        }

    }

    useEffect(() => {
        if (data && contactData) navigate(`/${checkout.uid}/payment`)
    }, [data, contactData])

    return (
        <div className="checkoutStepsWrapper">
            <div className="checkoutSteps">
                <Breadcrumb step={1} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.addressForm}>
                        <div className={styles.fullWidth}>
                            <h2 id="contact">Kontaktdaten</h2>
                        </div>
                        <div className={styles.fullWidth}>
                            <InputField value={checkout?.email} name="email" label="E-Mail" type="email" error={errors.email} register={register} validate={handleEmailValidation} required />
                        </div>
                        <div className={styles.fullWidth}>
                            <InputField value={checkout?.phone} name="phone" label="Telefon inkl. Landesvorwahl (optional)" type="text" error={errors.phone} register={register} validate={handlePhoneValidation} />
                        </div>
                        <div className={styles.fullWidth}>
                            <CheckboxField name="newsletter" checked={checkout?.newsletter} value={true} label={`Ja, ich will @${checkout?.publisher?.profile?.handle}'s Newsletter abonnieren!`} register={register} />
                        </div>
                        <div className={styles.fullWidth}>
                            <h2 id="customerData">Lieferadresse</h2>
                        </div>
                        <InputField value={checkout?.shippingAddress?.firstName} name="firstName" label="Vorname" type="text" error={errors.firstName} register={register} required />
                        <InputField value={checkout?.shippingAddress?.lastName} name="lastName" label="Nachname" type="text" error={errors.lastName} register={register} required />
                        <div className={styles.fullWidth}>
                            <InputField value={checkout?.shippingAddress?.company} name="company" label="Firma (optional)" type="text" register={register} registerData={("company")} />
                        </div>
                        <div className={styles.fullWidth}>
                            <InputField value={checkout?.shippingAddress?.address1} name="address1" label="Straße & Hausnummer" type="text" error={errors.address1} required register={register} />
                        </div>
                        <div className={styles.fullWidth}>
                            <InputField value={checkout?.shippingAddress?.address2} name="address2" label="Zusätzliche Adresse (optional)" type="text" register={register} />
                        </div>
                        <InputField value={checkout?.shippingAddress?.zip} name="zip" label="Postleitzahl" type="text" error={errors.zip} required register={register} />
                        <InputField value={checkout?.shippingAddress?.city} name="city" label="Stadt" type="text" error={errors.city} required register={register} />
                        <div className={styles.fullWidth}>
                            <SelectField options={countryOptions} value={checkout?.shippingAddress?.country?.code ? checkout?.shippingAddress?.country?.code : 'AT'} name="country" label="Land" error={errors.country} register={register} required />
                        </div>
                        <div className="spacer15"></div>
                        <div className={styles.fullWidth}>
                            <CheckboxField name="billingAddress" checked={checkout?.billingAddress} value={true} label="Abweichende Rechnungsadresse?" register={register} />
                        </div>
                        {
                            watchBillingAddress
                                ?
                                <>
                                    <div className={styles.fullWidth}>
                                        <h2 id="billingAddress">Rechnungsadresse</h2>
                                    </div>
                                    <InputField value={checkout?.billingAddress?.firstName} name="billingFirstName" label="Vorname" type="text" error={errors.billingFirstName} required register={register} />
                                    <InputField value={checkout?.billingAddress?.lastName} name="billingLastName" label="Nachname" type="text" error={errors.billingLastName} required register={register} />
                                    <div className={styles.fullWidth}>
                                        <InputField value={checkout?.billingAddress?.company} name="billingCompany" label="Firma (optional)" type="text" register={register} />
                                    </div>
                                    <div className={styles.fullWidth}>
                                        <InputField value={checkout?.billingAddress?.address1} name="billingAddress1" label="Straße & Hausnummer" type="text" error={errors.billingAddress1} required register={register} />
                                    </div>
                                    <div className={styles.fullWidth}>
                                        <InputField value={checkout?.billingAddress?.address2} name="billingAddress2" label="Zusätzliche Adresse (optional)" type="text" register={register} />
                                    </div>
                                    <InputField value={checkout?.billingAddress?.zip} name="billingZip" label="Postleitzahl" type="number" error={errors.billingZip} required register={register} />
                                    <InputField value={checkout?.billingAddress?.city} name="billingCity" label="Stadt" type="text" error={errors.billingCity} required register={register} />
                                    <div className={styles.fullWidth}>
                                        <SelectField options={countryOptions} value={checkout?.billingAddress?.country ? checkout?.billingAddress?.country?.code : 'AT'} name="billingCountry" label="Land" error={errors.billingCountry} register={register} required />
                                    </div>
                                    <div className="spacer15"></div>
                                </>
                                :
                                null
                        }
                        <div className="spacer15"></div>
                        <div className={styles.fullWidth}>
                            <SubmitButton register={register} value="Weiter zur Zahlung" loading={loading || contactLoading || billingLoading} />
                        </div>
                    </div>
                </form>
            </div>
            <Cart />
        </div>
    )
}
