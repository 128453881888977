import styles from '../../styles/CheckboxField.module.scss'

export default function CheckboxField({ label, name, value, setValue, register, required, type, checked }) {
    return (
        <div className={styles.fieldWrapper}>
            <label className={styles.label}>
                <input type="checkbox" name={name} defaultChecked={checked} value={value} className={styles.checkbox} {...register(name, { required: required ?? false })} /> <div>{label}</div>
            </label>
        </div>
    )
}