import { faThumbsUp, faFaceAnxiousSweat } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { NEWSLETTER_ACTIVATE } from '../lib/graphql'

import styles from '../styles/NewsletterActivation.module.scss'
import { useEffect } from 'react'

export default function NewsletterActivation({ }) {

    const { activationHash } = useParams()

    const [newsletterActivate, { data: activationData, loading: activationLoading, error: activationError }] = useMutation(NEWSLETTER_ACTIVATE);

    useEffect(() => {
        if (activationHash) {
            newsletterActivate({
                variables: {
                    activationHash: activationHash
                }
            })
        }
    }, [])

    return (
        <div className={styles.wrapper}>
            {activationData ? <p><FontAwesomeIcon icon={faThumbsUp} size="2xl" /><br /><br /><b>Vielen Dank!</b><br />Dein Newsletter-Abonnement wurde erfolgreich aktiviert.</p> : activationLoading && <p>Bitte warten…</p>}
            {activationError && <p><FontAwesomeIcon icon={faFaceAnxiousSweat} size="2xl" /><br /><br /><b>Ups!</b><br />Da ist etwas schief gegangen…</p>}
        </div>
    )
}